.nav-link {
  color: white;
  text-decoration: none;
}

.navbar-logo {
  display: block;
}

.sidebar-nav-link {
  position: relative;
  text-decoration: none;
}
.sidebar-nav-link.active {
  border-radius: 12px;
  background: linear-gradient(93deg, #6540EE 7.27%, #8062EE 77.03%);
}
.sidebar-nav-link.active .MuiListItemText-root {
  color: #fff;
}


