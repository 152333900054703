.viewer {
  width: 100%;
  height: 800px;
  position: relative;
}

.info-table {
  overflow-y: scroll;
  max-height: 800px;
}
.col1 {
  width: 100%;
}

.col2 {
  width: 100%;
}

.accrodion {
  --bs-accordion-color: #e0e6ec;
  --bs-accordion-bg: #191d21;
}
