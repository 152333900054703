.beta-anchor {
  z-index: 1000;
}

.blurry-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(7px);
  z-index: 10;
  pointer-events: none;
}
