.wrapper {
  display: flex;
  flex-flow: row wrap;
}

.wrapper>* {
  flex: 1 100%;
}

.section-1 {
  width: 100%;
  height: 400px;
  position: relative;
}

.section-2 {
  width: 100%;
  height: 400px;
  position: relative;
}

.section-3 {
  background-color: yellowgreen;
  width: 100%;
  height: 400px;
}