body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.myvideo {
  width: 100%;
  height: 100;
}

video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.content {
  width: 100%;
  height: 20%;
  position: absolute;
  top: 40%;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: rgb(11, 10, 10);
}

.full-viewer {
  /* width: 100%;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center; */
}


a {
  text-decoration: none;
  color: var(--primary-black, #040916)
}
/* for ketcher editor */
.Dialog-module_dialog__hiRBI {
  position: relative;
  z-index: 10 !important;
  width: 100%;
  height: 100%;
}