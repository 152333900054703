#viewport {
  width: 600px;
  height: 400px;
}

#ligand {
  width: 100%;
  height: 400px;
  position: relative;
}
