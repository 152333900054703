.wrapper {
  display: flex;
  flex-flow: row wrap;
}

.wrapper > * {
  flex: 1 100%;
}

#viewer {
  width: 100%;
  height: 800px;
  position: relative;
}
