.wrap-word{
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all
}

.super-script{
    font-size: 1rem;
    font-weight: bold;
}