#hole-viewer {
  width: 600px;
  height: 400px;
  background-color: black;
}

.plot-image {
  width: 600px;
  height: 400px;
}
